import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { Button, Typography, Grid, styled, Accordion, AccordionSummary, AccordionDetails, Alert, IconButton, Tooltip, Chip } from '@mui/material';
import { ArrowForward, Edit, ExpandLess, ExpandMore, FileDownloadRounded, VpnKeyRounded } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import { downloadTxtFile } from 'src/utilities/downloadTextFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Container } from '@mui/system';
import CopyButton from 'src/components/CopyButton';
import { Link } from 'react-router-dom';
import { sourceDisplayMap } from 'src/utilities/mapSourceDisplay';
import EditNickname from './editNickname';
import GeneralErrorAlert from 'src/components/GeneralErrorAlert';
import DisplayQuarterlyDates from 'src/components/DisplayQuarterlyDates';

import * as Constants from 'src/constants';
import TooltipInline from 'src/components/TooltipInline';
import AnnualDmfDisplay from 'src/components/AnnualDmfDisplay';

function ManageCustomerFileSetup({ result, displayClass, title, showMatchFileDropdown, matchFileResult, sources, file, transfer, frequencies, customerConfigs, matchConfigs, notificationEmails, pgp, sftpServer, hasMatchFileAssociated, goToMatchSetup, justCompleted, dateAdded, auditUser, categories, manageMatches, manageCustVdf, reload, setOpenAccordion, openAccordion, testFile, setFileType, setFilePrefix, setCustomerFilePrefix, testCustomerFilePrefix, showTestFileSuccess = false, setShowTestFileSuccess }) {  

  const CardWrapper = styled(Grid)(
    ({ theme }) => `
      .MuiCard-root {
        padding: 0px !important;
        margin-bottom: 27px;
      }
      .child-accordion .MuiCard-root {
        margin-bottom: 0px;
      }
      .key-icons {
        width: 16px;
        float: left;
        margin-right: 4px;
      }
      .success {
        color: ${theme.palette.success.main};
      }
      .error {
        color: ${theme.palette.error.main};
      }
      .pgp-button {
        font-weight:500;
        &.show-hide{
          text-transform: uppercase;
          padding-left: 0px;
        }
        &:hover {
          background-color: transparent;
        }
      }
      .copy-click-wrapper {
        float: left;
      }
      .date-line {
        background-color: ${theme.palette.background.default};
        border-top: 1px solid ${theme.palette.text.primary}22;
      }
    `
  );

  const AccordionWrapper = styled(Accordion)(
    ({ theme }) => `
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          border-top: 1px solid ${theme.palette.action.selected};
        }
        .MuiAccordionSummary-root {
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .MuiAccordionSummary-root.Mui-expanded {
          background-color: ${theme.palette.action.selected};
        }
        .MuiAccordionDetails-root {
          padding: 0px;
        }
        .child-accordion .MuiAccordionSummary-root, .child-accordion .MuiAccordionSummary-root:hover {
          min-height: 70px !important;
          max-height: 70px !important;
        }
        .child-accordion .MuiAccordionDetails-root {
          padding: 0px;
        }
       .MuiAccordionSummary-root[aria-expanded="true"] > .MuiAccordionSummary-content > .MuiTypography-root > .show-text {
          display: none;
        }
       .MuiAccordionSummary-root[aria-expanded="false"] > .MuiAccordionSummary-content > .MuiTypography-root > .hide-text {
          display: none;
        }
        .accordion-title-text {
          color: ${theme.palette.text.secondary};
        }
        @media screen and (max-width: 600px) {
          .child-accordion .MuiAccordion-root {
            margin-left: 0px;
            margin-right: 0px;
          }
        }
    `
  );

  const AlertWrapper = styled(Container)(
    ({ theme }) => `
    width: 100%;
    max-width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-top: 10px !important;
    margin-bottom: -20px;
      .alert-close {
        font-size: 13px;
      }
      .close-icon {
        margin-left: 3px;
        font-size: 20px;
      }
      @media screen and (max-width: 1279px) {
        .completed-alert {
          border-radius: 4px;
        }
      }
    a:-webkit-any-link {
      color: ${theme.colors.success.main}
    }
      `
  );

  const LinkWrapper = styled(Typography)(
    ({ theme }) => `
      a:-webkit-any-link {
        color: ${theme.colors.primary.main}
      }
    `
  );

  const [open, setOpen] = React.useState(false);
  const [editingNickname, setEditingNickname] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [accordionOpen, setAccordionOpen] =  useState(false);
  const [updatedNickname, setUpdatedNickname] = useState(result?.nickname);

  const editNickname = () => { setEditingNickname(true) }
  const cancelEditNickname = () => { setEditingNickname(false) }

  function setClosed() {
    setOpen(false);
  }

  const handleAccordion = () => {
    setOpenAccordion(null);
    setAccordionOpen(!accordionOpen);
  }

  const [showCustomerFileDetails, setShowCustomerFileDetails] = React.useState(false);

  function toggleCustomerFileDetails() {
    setShowCustomerFileDetails(!showCustomerFileDetails);
  }

  function downloadPGPKey() {
    downloadTxtFile(pgp?.publicKey, 'pgp-' + file?.prefix + '.txt')
  }

  function downloadSshKey() {
    downloadTxtFile(transfer?.sftp?.ssh?.publicKey, 'ssh-' + file?.prefix + '.txt')
  }

  const [showEmailAddresses, setShowEmailAddresses] = useState(false);

  function toggleShowEmailAddresses() {
    setShowEmailAddresses(!showEmailAddresses);
  }

  function onTestFileClicked() {
    setFileType(file?.type);
    setFilePrefix(file?.prefix);
    setCustomerFilePrefix(result?.customerFilePrefix);
    testFile(title);
  }

  useEffect(() => {
    if (notificationEmails?.length === 1) {
      setShowEmailAddresses(true);
    }
  }, [setShowEmailAddresses, notificationEmails?.length])

  useEffect(() => {
    if (!accordionOpen && (openAccordion && (openAccordion === matchFileResult?.[0]?.file?.prefix ? 'true' : 'false'))) {
      setAccordionOpen(true);
    }
  }, [accordionOpen, openAccordion, matchFileResult])

  return (
    <CardWrapper className={displayClass}>
      <Card sx={{ minWidth: 275, textAlign: 'left' }}>
        <Grid container spacing={2}>
          <Grid className={(!hasMatchFileAssociated && showMatchFileDropdown && !justCompleted) ? 'show' : 'hide'} item xs={12}>
            <AlertWrapper>
              <Alert
                className="completed-alert"
                variant="standard"
                severity="info"
                action={
                  <Button onClick={goToMatchSetup} className="finish-setup-button" variant="outlined" color="primary" >FINISH SETUP</Button>
                }>Setup in progress. Finish the Match Workflow setup to activate this configuration in LENS.</Alert>

            </AlertWrapper>
          </Grid>
          <Grid className={(justCompleted && open) ? 'show' : 'hide'} item xs={12}>
            <AlertWrapper>
              <Alert
                className="completed-alert"
                variant="standard"
                severity="success"
                action={
                  <IconButton className="alert-close"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={setClosed}
                  >
                    CLOSE <CloseIcon className='close-icon' fontSize="inherit" />
                  </IconButton>
                }>New configuration added successfully! Started submitting this list to LENS.</Alert>
            </AlertWrapper>
          </Grid>
          {generalizedError &&
            <Grid item xs={12} sx={{ mt: 2 }}>
              <GeneralErrorAlert />
            </Grid>
          }
          {showTestFileSuccess && (testCustomerFilePrefix === result?.customerFilePrefix) && title === 'Match Results' &&
            <AlertWrapper>
              <Grid item xs={12} sx={{ ml: 2, mt: 2 }}>
                <Alert
                  className="completed-alert"
                  severity="success"
                  action={
                    <IconButton className="alert-close"
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setShowTestFileSuccess(false)}
                    >
                      CLOSE <CloseIcon className='close-icon' fontSize="inherit" />
                    </IconButton>
                  }>Match results test file requested {DateTime.now().toFormat('MM/dd/yyyy')}. Track file status on <Link to="/matches-file">Match Results Activity</Link> page</Alert>
              </Grid>
            </AlertWrapper>
          }
          <Grid item xs={7}>
            {editingNickname ?
              <EditNickname data={result} nickname={updatedNickname || title} cancelEditNickname={cancelEditNickname} reload={reload} setGeneralizedError={setGeneralizedError} setOpenAccordion={setOpenAccordion} setUpdatedNickname={setUpdatedNickname} /> :
              <Typography variant="subtitle1" sx={{ mb: 1, px: 3, pt: 2 }} gutterBottom>
                {updatedNickname || title} <Tooltip arrow title="Rename"><IconButton aria-label="edit" onClick={editNickname}>
                  <Edit color="primary" sx={{ width: '18px', pb: '5px' }} />
                </IconButton></Tooltip>
              </Typography>
            }
          </Grid>
          <Grid item xs={5} sx={{ textAlign: 'right', pr: 2 }}>
            <Button onClick={onTestFileClicked} sx={{ mt: '12px', mr: 1 }} variant="outlined">Test File</Button>
            <Button onClick={() => { title === 'Match Results' ? manageMatches(result) : manageCustVdf(result) }} sx={{ mt: '12px' }} variant="contained">Manage</Button>
          </Grid>
        </Grid>
        <Grid className="date-line" container sx={{ padding: 0 }}>
          <Grid item xs={6} sx={{ px: 2, py: '3px' }}>
            <Typography variant="caption" className={(transfer?.encryptionEnabled || result?.configurationType === 'matches') ? 'success' : 'error'}><VpnKeyRounded className="key-icons" />{transfer?.sftp ? 'PGP ' : 'KMS '}{(transfer?.encryptionEnabled || result?.configurationType === 'matches') ? 'Key Encryption On' : 'Key Encryption Off'}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ px: 2, py: '3px', textAlign: 'right' }}>
            <Typography variant="caption">Added: {dateAdded} by {auditUser}</Typography>
          </Grid>
        </Grid>
        <CardContent sx={{ padding: '8px 16px 0px' }}>
          <Grid container sx={{ pl: { xs: 0, sm: 0 }, pt: 2 }}>
            <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: 4, mb: { xs: 0, sm: 5 } }}>
              <Typography variant="subtitle1" color='text.secondary'>Prefix Name:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant="subtitle1">{file?.prefix} </Typography>
              {file?.type !== 'both' ?
                <Typography variant="body1" sx={{ mb: 2 }}>{file?.prefix}_XXXXXXX.{file?.type}</Typography> :
                <>
                  <Typography variant="body1">{file?.prefix}_XXXXXXX.json</Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>{file?.prefix}_XXXXXXX.xlsx</Typography>
                </>}
            </Grid>
            {title === 'Match Results' &&
              <>
                <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: 4 }}>
                  <Typography variant="subtitle1" color='text.secondary'>Sources:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="subtitle1" color="text.primary" sx={{ mt: '2px', mb: 1 }}>{sources.length === 0 ? 'There are currently no sources assigned. Manage file to add sources.' : sources.map((source) => sourceDisplayMap[source]).join(', ')}</Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>This workflow will match your customer records against these sources.</Typography>
                </Grid>

                <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: 4 }}>
                  <Typography variant="subtitle1" color='text.secondary'>Match Categories:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Typography variant="subtitle1" color="text.primary" sx={{ mt: '2px', mb: 1 }}>
                      These categories will appear in your match results:
                    </Typography>
                    <LinkWrapper variant="body2" mt={0.7} ml={3}><Link target="_blank" to="/evadatas-match-categories">View Match Category Definitions</Link></LinkWrapper>
                  </Grid>
                  <Typography variant="body2" sx={{ mb: 2 }}>{categories ? 
                  categories.map((cat) => {
                    return <Chip key={cat} sx={{mr: 1, pl: '3px', pr: '3px', mb: '3px'}} size="small" label={cat} />
                  }) 
                  : ''}</Typography>
                </Grid>
              </>
            }
            <Grid item xs={12} sm={3}>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Button className="pgp-button show-hide" variant="text" size="medium" sx={{ mb: 2 }} onClick={toggleCustomerFileDetails}>Show {showCustomerFileDetails ? <>Less <ExpandLess /></> : <>More <ExpandMore /></>}</Button>
            </Grid>
          </Grid>
          <Grid className={showCustomerFileDetails ? 'show' : 'hide'} container sx={{ pt: 0 }}>
            <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: { xs: 0, sm: 4 } }}>
              <Typography variant="subtitle1" color='text.secondary'>Transfer Location:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>{transfer?.type === 'sftp' ? 'SFTP' : 'AWS S3'}<Button component={Link} variant="text" sx={{ float: 'right' }} to="/transfer-locations" endIcon={<ArrowForward />}>VIEW ALL LOCATIONS</Button></Typography>
              {transfer?.type === 'sftp' &&
                <>
                  <Typography color="text.secondary" variant="overline">{title === 'Match Results' ? 'PROVIDED BY MY ORGANIZATION' : 'PROVIDED BY LENS'}</Typography>
                  <Typography variant="body1" sx={{ mb: 0 }}><strong>{title !== 'Match Results' && 'LENS '}SFTP Server Hostname:</strong> {title !== 'Match Results' && <CopyButton display="inline" toolTipText="Copy SFTP Server" textToCopy={title === 'Match Results' ? transfer?.sftp?.host : sftpServer} />}
                    {title === 'Match Results' ? transfer?.sftp?.host : sftpServer}
                  </Typography>
                  {title !== 'Match Results' && 
                    <>
                      <Typography variant="body1" sx={{ mb: 0.5 }}><strong>Subdirectory:</strong> {title === 'Customer List' ? '/customer_files' : '/death_files'} </Typography>
                      <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}><strong>Port:</strong> {Constants.lensSftpPort}</Typography>
                    </>
                  }
                  {title === 'Match Results' ? '' : <Typography color="text.secondary" variant="overline">PROVIDED BY MY ORGANIZATION</Typography>}
                  {title === 'Match Results' && <Typography variant="body1" sx={{ mb: 1, mt: 1 }}><strong>Port:</strong> {transfer?.sftp?.port}</Typography>}
                  <Typography className={(transfer?.sftp?.path && transfer?.sftp?.path !== '/') ? 'show' : 'hide'} variant="body1" sx={{ mb: 1, mt: 1 }}><strong>Subdirectory Path:</strong> {transfer?.sftp?.path}</Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}><strong>Username:</strong> {transfer?.sftp?.username}</Typography>
                  {(title === 'Match Results' && !transfer?.sftp?.ssh?.publicKey) && <Typography variant="body1" sx={{ mb: 1 }}><strong>Password: </strong>For security reasons, the password is hidden.</Typography>}
                  {title !== 'Match Results' && <Typography variant="body1" sx={{ mb: 1 }}><strong>SSH-RSA key:</strong>
                     For security reasons, the SSH-RSA key is hidden.
                  </Typography>}
                  {(title === 'Match Results' && transfer?.sftp?.ssh?.publicKey) && <><Typography color="text.secondary" variant="overline">PROVIDED BY LENS</Typography>
                    <Typography variant="body1" sx={{ mb: 1, mt: -1 }}><strong>SSH-RSA key: </strong>
                      <CopyButton className="ssh-copy-button" display="inline" paddingLeft={2} buttonText="Copy Public SSH-RSA Key" toolTipText="Copy Public SSH-RSA Key" textToCopy={transfer?.sftp?.ssh?.publicKey}></CopyButton>
                      <Button className="pgp-button" onClick={downloadSshKey} sx={{ marginTop: '-1px', marginRight: '-10px' }}>Download SSH-RSA Key<FileDownloadRounded sx={{ ml: 1 }} /></Button>
                    </Typography></>}
                </>
              }
              {transfer?.type === 'awsS3' &&
                <>
                  <Typography color="text.secondary" variant="overline">PROVIDED BY MY ORGANIZATION</Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}><strong>AWS Account ID:</strong> {transfer?.awsS3?.carrierIntegrationAccountId}</Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}><strong>S3 Bucket ARN:</strong> {transfer?.awsS3?.s3Bucket}</Typography>
                  <Typography className={(transfer?.awsS3?.path && transfer?.awsS3?.path !== '/') ? 'show' : 'hide'} variant="body1" sx={{ mb: 1, mt: 1 }}><strong>Folder Path:</strong> {transfer?.awsS3?.path}</Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}><strong>IAM Role ARN:</strong> {transfer?.awsS3?.iamRole}</Typography>
                </>
              }
              <Typography variant="body1" sx={{ mb: 1 }}><strong>Transfer Schedule: </strong>
                {transfer?.frequency === 'daily' ?
                  <Box sx={{ mb: 4, textTransform: 'capitalize', display: 'inline' }}>Daily (Monday-Friday)</Box> :
                  <Box sx={{ mb: 4, textTransform: 'capitalize', display: 'inline' }}>{transfer?.frequency}{(title === 'Match Results' && transfer?.frequencyDay) ? ' (' + transfer?.frequencyDay + ')' : ''} </Box>}
                {title === 'Match Results' && <TooltipInline placement="right" display='inline-flex' mt={-1.5} tip='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly or once). This sets the frequency of scanning/match results delivery to your organization.' />}
                <br/>
                {transfer?.reportDates &&
                  <DisplayQuarterlyDates reportDates={transfer?.reportDates} />
                }
              </Typography>
              {transfer?.type === 'sftp' &&
                <Typography variant="body2">{title === 'Customer List' && "At the transfer frequency, place your list in the SFTP subdirectory (/customer_files)"}
                  {title === 'Verified Deaths List' && " At the transfer frequency, place your list in the SFTP subdirectory (/death_files)"}
                  {title === 'Match Results' && 'At this frequency, LENS uses the credentials above to place matches in your SFTP'}.</Typography>
              }
              {transfer?.type === 'awsS3' &&
                <Typography variant="body2">{title === 'Match Results' ? 'At the transfer frequency, LENS will place your matches in the S3 Bucket.' : 'At the transfer frequency, place your list in the S3 Bucket.'}</Typography>
              }
              {transfer?.frequency === 'daily' && title === 'Match Results' &&
                <>
                  <Typography variant="body1" sx={{ mb: 1, mt: 1 }}><strong>Observe Company Holidays: </strong>
                    <Box sx={{ mb: 4, textTransform: 'capitalize', display: 'inline' }}>
                      {result?.holidayCalendarId ? 'Yes' : 'No'}{result?.holidayCalendarId && <TooltipInline placement="right" display='inline-flex' mt={-1.5} tip='Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company’s holiday calendar, and will resume the following day (Mon.-Fri.).'></TooltipInline>}
                    </Box>
                  </Typography>
                  <Typography variant="body2" color="text.primary" sx={{ mb: 1.5 }}>Visit <Link to='/holiday-calendar'>Holiday Calendar</Link> page to manage holidays.</Typography>
                </>
              }
              {title === 'Match Results' && <AnnualDmfDisplay spacing={0.5} variant='list' frequencies={frequencies} matchConfig={result} matchConfigs={matchConfigs} customerConfigs={customerConfigs} />}
            </Grid>
          </Grid>
          <Grid className={showCustomerFileDetails ? 'show' : 'hide'} container sx={{ pt: 2 }}>
            <Grid className={(transfer?.sftp?.pgp || result?.configurationType === 'matches' || ((transfer?.awsS3?.kmsArn || transfer?.awsS3?.evadataMatchLambdaKmsArn) && transfer?.encryptionEnabled)) ? 'show' : 'hide'} item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: { xs: 0, sm: 4 } }}>
              <Typography variant="subtitle1" color='text.secondary'>Encryption Key:</Typography>
            </Grid>
            <Grid className={(transfer?.sftp?.pgp || result?.configurationType === 'matches' || ((transfer?.awsS3?.kmsArn || transfer?.awsS3?.evadataMatchLambdaKmsArn) && transfer?.encryptionEnabled)) ? 'show' : 'hide'} item xs={12} sm={9}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>{transfer?.type === 'sftp' ? 'PGP' : 'AWS KMS'}<Button component={Link} variant="text" sx={{ float: 'right' }} to="/encryption-keys" endIcon={<ArrowForward />}>VIEW ALL KEYS</Button></Typography>
              {transfer?.type === 'sftp' &&
                <>
                  <Typography color="text.secondary" variant="overline"> {title === 'Match Results' ? 'PROVIDED BY MY ORGANIZATION' : 'PROVIDED BY LENS'}</Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}><strong>PGP Key ID: </strong>{title === 'Match Results' ? transfer?.sftp?.pgp?.keyId : pgp?.id}</Typography>
                  <Typography variant="body1" sx={{ mb: 0 }}><strong>{title === 'Match Results' ? 'Key Expiration:' : 'Key Rotation:'}</strong> {title === 'Match Results' ? transfer?.sftp?.pgp?.keyExpiration : pgp?.keyRotationInYears + ' Years'}  {title !== 'Match Results' && '(Expires ' + pgp?.keyExpiration + ')'}</Typography>
                </>
              }
              {transfer?.type === 'awsS3' &&
                <>
                  <Typography color="text.secondary" variant="overline"> {title === 'Match Results' ? 'PROVIDED BY LENS' : 'PROVIDED BY MY ORGANIZATION'}</Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}><strong>KMS Key ARN: </strong>{title === 'Match Results' && <CopyButton display="inline" toolTipText="Copy KMS Arn" textToCopy={transfer?.awsS3?.evadataMatchLambdaKmsArn} />} {title === 'Match Results' ? transfer?.awsS3?.evadataMatchLambdaKmsArn : transfer?.awsS3?.kmsArn}</Typography>
                  <Typography variant="body2">{title === 'Match Results' ? 'Use this KMS Key ARN to decrypt your matches.' : 'Encrypt list with the KMS key. LENS will use this KMS Key ARN to decrypt the transferred list.'}</Typography>
                </>
              }
            </Grid>
            {transfer?.type === 'sftp' && (pgp?.publicKey || result?.configurationType === 'matches') &&
              <>
                <Grid className={title !== 'Match Results' ? 'show' : 'hide'} item xs={8} sx={{ pl: { xs: 0, sm: '25.4%' }, pt: 1, display: { xs: 'none', sm: 'block' } }}>
                  <div className='copy-click-wrapper'>
                    <CopyButton paddingLeft={0} buttonText="Copy Public Key" toolTipText="Copy Public Key" textToCopy={pgp?.publicKey}></CopyButton>
                  </div>
                  <Button className="pgp-button" onClick={downloadPGPKey} sx={{ marginTop: '-3px', marginRight: '-10px' }}>Download Public Key<FileDownloadRounded sx={{ ml: 1 }} /></Button>
                  <br />
                  <Typography variant="body2" sx={{ marginLeft: { sm: '-5px', xs: 0 } }}>
                    {title === 'Customer List' ? 'Use the public PGP key to encrypt your Customer list.' : ''}
                    {title === 'Verified Deaths List' ? 'Use the public PGP key to encrypt your Verified Deaths list.' : ''}</Typography>
                </Grid>
                <Grid item className={title === 'Match Results' ? 'show' : 'hide'} xs={8} sx={{ pl: { xs: 0, sm: '25.4%' }, pt: 1, display: { xs: 'none', sm: 'block' } }}>
                  <Typography variant="body2" sx={{ marginLeft: { sm: '-5px', xs: 0 } }}>
                    LENS encrypts matches with the public PGP key provided. Use private key to decrypt matches.
                  </Typography>
                </Grid>
              </>
            }
            <Grid className={showCustomerFileDetails ? 'show' : 'hide'} container sx={{ pt: 2 }}>
              <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: { xs: 0, sm: 4 } }}>
                <Typography variant="subtitle1" color='text.secondary'>Notifications:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography variant="body2" sx={{ mt: '5px' }}>Email(s) receiving notifications related to this list.</Typography>
                {notificationEmails?.length > 1 &&
                  <Button className="pgp-button show-hide" variant="text" size="medium" onClick={toggleShowEmailAddresses}>{showEmailAddresses ? <>Hide Email Addresses <ExpandLess /></> : <>Show Email Addresses <ExpandMore /></>}</Button>
                }
                {showEmailAddresses &&
                  <>
                    {notificationEmails.map((email) => {
                      return <Typography variant='subtitle1' key={email}>{email}</Typography>
                    })}
                  </>
                }
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
                
        {(showMatchFileDropdown && (hasMatchFileAssociated || justCompleted)) &&
          <Grid container sx={{ mt: 4 }}>
            <Grid className="child-accordion" item xs>
              <AccordionWrapper expanded={accordionOpen} onChange={handleAccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="subtitle1">View Match Workflows<Typography sx={{ ml: 3 }} variant="body2" display="inline">1 workflow assigned</Typography></Typography>
                </AccordionSummary>
                <AccordionDetails>

                  {matchFileResult?.map((result) => (
                    <div key={result?.customerFilePrefix}>
                      {(result?.customerFilePrefix === file?.prefix) &&
                        <ManageCustomerFileSetup
                          key={result?.file?.prefix}
                          title='Match Results'
                          frequencies={frequencies}
                          customerConfigs={customerConfigs}
                          matchConfigs={matchConfigs}
                          showMatchFileDropdown={false}
                          result={result}
                          file={result?.file}
                          transfer={result?.transfer}
                          notificationEmails={result?.notificationEmails}
                          pgp={result?.pgp}
                          sftpServer={result?.sftpServer}
                          sources={result?.sources}
                          categories={result?.categories}
                          dateAdded={result?.auditDate}
                          auditUser={result?.auditUser}
                          manageMatches={manageMatches}
                          reload={reload}
                          setOpenAccordion={setOpenAccordion}
                          openAccordion={openAccordion}
                          testFile={testFile}
                          setFilePrefix={setFilePrefix}
                          setFileType={setFileType}
                          setCustomerFilePrefix={setCustomerFilePrefix}
                          testCustomerFilePrefix={testCustomerFilePrefix}
                          showTestFileSuccess={showTestFileSuccess}
                          setShowTestFileSuccess={setShowTestFileSuccess}
                        />
                      }
                    </div>
                  ))}
                </AccordionDetails>
              </AccordionWrapper>
            </Grid>
          </Grid>
        }
      </Card>
    </CardWrapper>
  );
}

ManageCustomerFileSetup.propTypes = {
  result: PropTypes.object,
  displayClass: PropTypes.string,
  title: PropTypes.string,
  showMatchFileDropdown: PropTypes.bool, matchFileResult: PropTypes.array,
  sources: PropTypes.array,
  file: PropTypes.object,
  transfer: PropTypes.object,
  frequencies: PropTypes.object,
  notificationEmails: PropTypes.array,
  pgp: PropTypes.object,
  sftpServer: PropTypes.string,
  hasMatchFileAssociated: PropTypes.bool, goToMatchSetup: PropTypes.func,
  justCompleted: PropTypes.bool,
  dateAdded: PropTypes.string,
  auditUser: PropTypes.string,
  categories: PropTypes.array,
  manageMatches: PropTypes.func,
  manageCustVdf: PropTypes.func,
  reload: PropTypes.func,
  setOpenAccordion: PropTypes.func.isRequired,
  openAccordion: PropTypes.string.isRequired,
  customerConfigs: PropTypes.array,
  matchConfigs: PropTypes.array,
  testFile: PropTypes.func,
  setFileType: PropTypes.func,
  setFilePrefix: PropTypes.func,
  setCustomerFilePrefix: PropTypes.func,
  showTestFileSuccess: PropTypes.bool,
  setShowTestFileSuccess: PropTypes.func,
  testCustomerFilePrefix: PropTypes.string
};

export default ManageCustomerFileSetup;
