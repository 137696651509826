import { React, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Box, TextField, IconButton, CircularProgress, Tooltip } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { updateFileTag } from 'src/api';
import { useAppContext } from 'src/AppContext';

function Loading() {
  return (
    <Box sx={{ display: 'inline-block', mt: 2, ml: 2 }}>
      <CircularProgress size="5vh" className="spinner" />
    </Box>
  );
}

const validationSchema = yup.object().shape({
  nickname: yup
    .string()
    .required('This field is required')
})

function EditNickname({ data, nickname, cancelEditNickname, setGeneralizedError, reload, setOpenAccordion, setUpdatedNickname }) {
  const [isLoading, setIsLoading] = useState(false);
  const { carrierConfig } = useAppContext();

  const formik = useFormik({
    initialValues: {
      nickname: nickname,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let payload = {
          'type': 'nickname',
          'tagValue': values?.nickname,
        };
        let fileType = data?.configurationType;
        let fileTypes = {
          'customer_records': 'customer-files',
          'death_records': 'death-files',
          'matches': 'match-files'
        }
        let prefix;
        if (fileType === 'matches') {
          prefix = data?.customerFilePrefix;
        } else {
          prefix = data?.file?.prefix;
        }
        setIsLoading(true);
        const result = await updateFileTag(carrierConfig?.carrierId, payload, fileTypes[fileType], prefix, 'nickname');
        if (result.statusCode === 400 || result.statusCode === 500) {
          setIsLoading(false);
          window.scrollTo(0, 0);
          setGeneralizedError(true);
        }
        if (result?.tagValue) {
          if (fileType === 'matches') {
            setOpenAccordion(data?.file?.prefix);
          }
          setUpdatedNickname(result?.tagValue);
          reload();
          cancelEditNickname();
          setIsLoading(false);
        }
      }
      catch (e) {
        setIsLoading(false);
        throw (e);
      }
    },
  });

  return (
    <FormikProvider validateOnChange value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          inputRef={input => input?.focus}
          disabled={isLoading}
          id="nickname"
          name="nickname"
          label="Name"
          value={formik?.values?.nickname}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik?.errors?.nickname}
          helperText={formik?.errors?.nickname ? formik?.errors?.nickname : ''}
          sx={{ mt: 1, ml: 1, mb: 1, minWidth: '400px' }}
          inputProps={{ maxLength: 32 }} />
        {!isLoading &&
          <>
            <Tooltip arrow title="Save"><IconButton type="submit" sx={{ mt: 2, ml: 1, mr: 1 }}><Check color="success" /></IconButton></Tooltip>
            <Tooltip arrow title="Cancel"><IconButton sx={{ mt: 2 }} onClick={cancelEditNickname}><Close color="error" /></IconButton></Tooltip>
          </>
        }
        {isLoading && <Loading />}
      </form>
    </FormikProvider>
  )
}

EditNickname.propTypes = {
  data: PropTypes.object.isRequired,
  nickname: PropTypes.string.isRequired,
  cancelEditNickname: PropTypes.func.isRequired,
  setGeneralizedError: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  setOpenAccordion: PropTypes.func.isRequired,
  setUpdatedNickname: PropTypes.func
};

export default EditNickname;