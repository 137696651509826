import React, { useEffect, useState } from 'react';

import { useAppContext } from 'src/AppContext.js';
import { getCustomerFileConfigurations, getDeathFileConfigurations, getMatchFileConfigurations, getEmailAddresses, getSftpUsernames, getHolidayCalendars, getTransferFrequencies } from 'src/api';
import ManageCustomerFileSetup from './manageCustomerFileSetup';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import PageHeader from 'src/components/PageHeader';
import { ArrowBack, SettingsSuggestRounded } from '@mui/icons-material';
import { Alert, Button, Grid, styled, Typography, Card } from '@mui/material';
import AddCustomerConfiguration from './addCustomerConfiguration';
import AddMatchFileConfiguration from './addMatchFileConfiguration';
import { Container } from '@mui/system';
import LoadError from 'src/components/LoadError';
import ManageMatchResults from './manageMatchResults';
import ManageCustVdfLists from './manageCustVdfLists';
import { useNavigate } from 'react-router-dom';
import AddDeathConfiguration from './addDeathConfiguration';
import MultiConfigButton from './multiButton';
import TestFile from 'src/components/TestFile';

const AlertWrapper = styled(Container)(
  ({ theme }) => `
  width: 300px;
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 340px !important;
  position: fixed;
  z-index: 999999;
  top: 70px;
  box-shadow: 0px 2px 4px ${theme.palette.text.primary}99;
  border-radius: 4px;
  cursor: pointer;
    `
);

function Loading() {
  return (
    <LoadingIcon />
  );
}

function ManageConfigurations() {
  const [customerResult, setCustomerResult] = useState(null);
  const [customerResults, setCustomerResults] = useState(null);
  const [verifiedDeathsResult, setVerifiedDeathsResult] = useState(null);
  const [matchFileResult, setMatchFileResult] = useState(null);
  const [matchFileResults, setMatchFileResults] = useState(null);
  const [holidayCalendarId, setHolidayCalendarId] = useState(null);
  const [frequencies, setFrequencies] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showAddCustConfig, setShowAddCustConfig] = useState(false);
  const [showAddDeathConfig, setShowAddDeathConfig] = useState(false);
  const [showAddMatchConfig, setShowAddMatchConfig] = useState(false);
  const { carrierConfig } = useAppContext();
  const [availableEmailAddresses, setAvailableEmailAddresses] = useState(null);
  const [customerPrefixes, setCustomerPrefixes] = useState(null);
  const [deathPrefixes, setDeathPrefixes] = useState(null);
  const [matchPrefixes, setMatchPrefixes] = useState(null);
  const [customerFilePgp, setCustomerFilePgp] = useState('');
  const [customerFilePrefix, setCustomerFilePrefix] = useState('');
  const [justCompleted, setJustCompleted] = useState('');
  const [showCompletedAlert, setShowCompletedAlert] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [stateRefreshCount, setStateRefreshCount] = useState(0);
  const [usernames, setUsernames] = useState(null);
  const [showNewConfigButton, setShowNewConfigButton] = useState(false);
  const [showManageMatches, setShowManageMatches] = useState(false);
  const [matchesToManage, setMatchesToManage] = useState(null);
  const [showManageCustVdf, setShowManageCustVdf] = useState(false);
  const [testFile, setTestFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [filePrefix, setFilePrefix] = useState(null);
  const [testCustomerFilePrefix, setTestCustomerFilePrefix] = useState(null);
  const [showTestFileSuccess, setShowTestFileSuccess] = useState(false);
  const [custVdfToManage, setCustVdfToManage] = useState(null);
  const navigate = useNavigate();
  const {lensRoles } = useAppContext();
  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    if (lensRoles && !lensRoles?.includes('administrator')) {
      navigate('/400');
    }
  }, [lensRoles, navigate])

  useEffect(() => {
    const getCustomerConfigurations = async function (carrierId) {
      const configs = await getCustomerFileConfigurations(carrierId);
      configs.sort(function (a, b) {
        return new Date(b.auditDate) - new Date(a.auditDate);
      });
      setCustomerResult(configs[0]);
      setCustomerResults(configs);
      const _prefixes = configs.map(x => x?.file?.prefix);
      setCustomerPrefixes(_prefixes);
      setShowNewConfigButton(true);
    };

    const getVFDConfigurations = async function (carrierId) {
      const configs = await getDeathFileConfigurations(carrierId);
      setVerifiedDeathsResult(configs);
      const _prefixes = configs.map(x => x?.file?.prefix);
      setDeathPrefixes(_prefixes);
    };

    const getMatchConfigurations = async function (carrierId) {
      const configs = await getMatchFileConfigurations(carrierId);

      if (configs.length > 0) {
        setMatchFileResults(configs);
        setMatchFileResult(configs[0]);
        const _prefixes = configs.map(x => x?.file?.prefix);
        setMatchPrefixes(_prefixes);
      }
    };

    const getHolidayCalendarId = async function (carrierId) {
      const calendars = await getHolidayCalendars(carrierId);
      setHolidayCalendarId(calendars[0]?.id);
    };

    const getEmails = async function (carrierId) {
      const emails = await getEmailAddresses(carrierId);
      const emailsWithId = emails.map((email, index) => ({'id' : index, 'email': email}));
      setAvailableEmailAddresses(emailsWithId);
    };

    const getUsernames = async function (carrierId) {
      const _usernames = await getSftpUsernames(carrierId);
      if (_usernames.length > 0) {
        setUsernames(_usernames);
      }
    };

    const getAvailableFrequencies = async function (carrierId) {
      const frequencies = await getTransferFrequencies(carrierId);
      setFrequencies(frequencies);
    };

    const getAllData = async function (carrierId) {
    if (carrierId) {
      await Promise.all([
        getVFDConfigurations(carrierId),
        getCustomerConfigurations(carrierId),
        getMatchConfigurations(carrierId),
        getHolidayCalendarId(carrierId),
        getAvailableFrequencies(carrierId),
        getEmails(carrierId),
        getUsernames(carrierId)
      ]).finally(() => setIsLoading(false))
    } else {
      setLoadError(true);
    }
  }
  carrierConfig?.carrierId && getAllData(carrierConfig?.carrierId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierConfig?.carrierId, stateRefreshCount]);

  const goToMatchSetup = (result) => {
    setShowAddCustConfig(false);
    setShowAddMatchConfig(true);
    setCustomerFilePgp(result?.transfer?.sftp?.pgp?.publicKey);
    setCustomerFilePrefix(result?.file?.prefix);
    window.scrollTo(0, 0);
  }

  async function finishSetup(prefix) {
    const configs = await getCustomerFileConfigurations(carrierConfig?.carrierId);
    if (configs.length > 0) {
      setCustomerResult(configs[0]);
      setCustomerResults(configs);
      let _prefixes = [];
      _prefixes.push(configs[0]?.file?.prefix)
      setCustomerPrefixes(_prefixes);
    }
    const deathConfigs = await getDeathFileConfigurations(carrierConfig?.carrierId);
    if (deathConfigs.length > 0) {
      setVerifiedDeathsResult(deathConfigs);
      let _prefixes = [];
      _prefixes.push(deathConfigs[0]?.file?.prefix)
      setDeathPrefixes(_prefixes);
    }
    const matchConfigs = await getMatchFileConfigurations(carrierConfig?.carrierId);
    if (matchConfigs.length > 0) {
      setMatchFileResults(matchConfigs);
    }
    setShowAddCustConfig(false);
    setShowAddMatchConfig(false);
    window.scrollTo(0, 0);
    setJustCompleted(prefix);
    reload();
  }

  function checkForMatchFile(result) {
    for (let i = 0; i < matchFileResults?.length; i++) {
      if (matchFileResults[i]?.customerFilePrefix === result?.file?.prefix) {
        return true;
      }
    }
  }

  function checkJustCompleted(result) {
    if (result?.file?.prefix === justCompleted) {
      return true;
    }
  }

  function clearCompletedAlert() {
    setShowCompletedAlert(false);
  }

  function cancel() {
    window.scroll(0,0);
    setShowAddCustConfig(false);
    setShowAddMatchConfig(false);
    setShowAddDeathConfig(false);
    setShowManageMatches(false);
    setShowManageCustVdf(false);
    setTestFile(null);
    finishSetup()
      .then(() => setLoadError(false))
      .catch((error) => {
        navigate('/500?page=manage-configurations&error=' + error);
      });
  }

  function reload() {
    const counter = (stateRefreshCount + 1);
    setStateRefreshCount(counter);
    setLoadError(false);
  }

  function manageMatches(result) {
    setShowManageMatches(true);
    setMatchesToManage(result);
  }

  function manageCustVdf(result) {
    setShowManageCustVdf(true);
    setCustVdfToManage(result);
  }

  function backToAll() {
    setShowAddCustConfig(false);
    setShowAddDeathConfig(false);
    setShowAddMatchConfig(false);
  }

  return (
    <>
      {loadError ? <LoadError reload={reload} /> :
        <>
          <Grid container>
            <AlertWrapper>
              <Alert onClick={clearCompletedAlert} className={showCompletedAlert ? 'show' : 'hide'} severity="success">Configuration Added Successfully</Alert>
            </AlertWrapper>
            <AlertWrapper>
              <Alert onClick={() => setShowTestFileSuccess(false)}  className={showTestFileSuccess ? 'show' : 'hide'} severity="success">Match results test file requested.</Alert>
            </AlertWrapper>
            <Grid item sm={9}>
              {
                (!showAddCustConfig && !showAddMatchConfig && !showManageMatches && !showManageCustVdf && !showAddDeathConfig && !testFile) && <PageHeader title="List Configurations" subtitle="View of all your organization's list configurations." icon={<SettingsSuggestRounded />} />
              }
              {
                (showAddCustConfig || showAddMatchConfig) && <PageHeader title="Add Configuration" subtitle="Set up new customer list and match workflow." />
              }
              {
                showAddDeathConfig && <PageHeader title="Add Configuration" subtitle="Set up your verified deaths list." />
              }
            </Grid>
            <Grid item sm={3} sx={{ textAlign: 'right' }}>
              {(!showAddCustConfig && !showAddMatchConfig && !showManageMatches && !showManageCustVdf && !showAddDeathConfig && !testFile && showNewConfigButton) &&
                <MultiConfigButton  setShowAddCustConfig={setShowAddCustConfig} setShowAddDeathConfig={setShowAddDeathConfig} />
              }
              {(showAddCustConfig || showAddMatchConfig || showAddDeathConfig) && <Button sx={{ mt: 2 }} variant='text' color="primary" onClick={backToAll}><ArrowBack /> Back To All Lists</Button>}
            </Grid>
          </Grid>
          <div className={(!isLoading && !showAddCustConfig && !showAddMatchConfig && !showManageMatches && !showManageCustVdf && !showAddDeathConfig && !testFile) ? 'show' : 'hide'}>
            <Typography variant="h5" sx={{ mb: 4 }}>Customer Lists ({customerResults?.length})</Typography>
            {
              customerResults?.map((result) => (
                <ManageCustomerFileSetup
                  result={result}
                  key={result?.file?.prefix}
                  title='Customer List'
                  showMatchFileDropdown={true}
                  matchFileResult={matchFileResults}
                  file={result?.file}
                  transfer={result?.transfer}
                  frequencies={frequencies}
                  customerConfigs={customerResults}
                  matchConfigs={matchFileResults}
                  notificationEmails={result?.notificationEmails}
                  pgp={result.transfer?.sftp?.pgp}
                  sftpServer={result.transfer?.sftp?.sftpServer}
                  justCompleted={checkJustCompleted(result)}
                  hasMatchFileAssociated={checkForMatchFile(result)}
                  goToMatchSetup={() => goToMatchSetup(result)}
                  dateAdded={result?.auditDate}
                  auditUser={result?.auditUser}
                  manageMatches={manageMatches}
                  manageCustVdf={manageCustVdf}
                  reload={reload}
                  setOpenAccordion={setOpenAccordion}
                  openAccordion={openAccordion}
                  testFile={setTestFile}
                  setFileType={setFileType}
                  setFilePrefix={setFilePrefix}
                  setCustomerFilePrefix={setTestCustomerFilePrefix}
                  testCustomerFilePrefix={testCustomerFilePrefix}
                  showTestFileSuccess={showTestFileSuccess}
                  setShowTestFileSuccess={setShowTestFileSuccess}
                />
              ))
            }
            <Typography variant="h5" sx={{ mb: 4, mt: 4 }}>Verified Deaths ({verifiedDeathsResult?.length})</Typography>
            {
              verifiedDeathsResult?.length > 0 &&
                verifiedDeathsResult?.map((result) =>  (
                  <ManageCustomerFileSetup
                    key={result?.file?.prefix}
                    title='Verified Deaths List'
                    result={result}
                    showMatchFileDropdown={false}
                    file={result.file}
                    transfer={result.transfer}
                    notificationEmails={result.notificationEmails}
                    pgp={result.transfer?.sftp?.pgp}
                    sftpServer={result.transfer?.sftp?.sftpServer}
                    setResult={setVerifiedDeathsResult}
                    dateAdded={result?.auditDate}
                    auditUser={result?.auditUser}
                    manageCustVdf={manageCustVdf}
                    justCompleted={checkJustCompleted(result)}
                    reload={reload}
                    testFile={setTestFile}
                    setFileType={setFileType}
                    setFilePrefix={setFilePrefix}
                    setCustomerFilePrefix={setTestCustomerFilePrefix}
                  />
                ))
            }
            {
              verifiedDeathsResult?.length === 0 &&
                <Card>
                  <Typography sx={{p: 4}}>No lists to display.</Typography>
                </Card>
            }
          </div>

          {!isLoading && showAddCustConfig && <AddCustomerConfiguration customerResult={customerResult} availableEmailAddresses={availableEmailAddresses} customerPrefixes={customerPrefixes} goToMatchSetup={goToMatchSetup} cancel={cancel} usernames={usernames} reload={reload} />}

          {!isLoading && showAddDeathConfig && <AddDeathConfiguration availableEmailAddresses={availableEmailAddresses} customerPrefixes={customerPrefixes} setJustCompleted={setJustCompleted} existingTransferUser={customerResult?.transfer?.sftp?.username} sftpServer={customerResult?.transfer?.sftp?.sftpServer} reload={reload} cancel={cancel} setShowAddDeathConfig={setShowAddDeathConfig}  usernames={usernames} deathPrefixes={deathPrefixes} transferType={customerResult?.transfer?.type} awsAccountID={customerResult?.transfer?.awsS3?.carrierIntegrationAccountId} evadataLambdaArn={customerResult?.transfer?.awsS3?.evadataLambdaArn} evadataLambdaRoleArn={customerResult?.transfer?.awsS3?.evadataLambdaRoleArn}/>}

          {!isLoading && showAddMatchConfig && <AddMatchFileConfiguration customerResults={customerResults} matchResults={matchFileResults} matchResult={matchFileResult} availableEmailAddresses={availableEmailAddresses} matchPrefixes={matchPrefixes} finishSetup={() => finishSetup(customerFilePrefix)} customerFilePgp={customerFilePgp} customerFilePrefix={customerFilePrefix} cancel={cancel} usernames={usernames} holidayCalendarId={holidayCalendarId} />}

          {(!isLoading && showManageMatches) &&
            <ManageMatchResults data={matchesToManage} cancel={cancel} reload={reload} frequencies={frequencies} customerConfigs={customerResults} matchConfigs={matchFileResults}/>}

          {(!isLoading && showManageCustVdf) &&
            <ManageCustVdfLists data={custVdfToManage} cancel={cancel} reload={reload} />}

          <TestFile type={testFile} fileType={fileType} filePrefix={filePrefix} customerFilePrefix={testCustomerFilePrefix} setShowSuccess={setShowTestFileSuccess} cancel={cancel} />

          {isLoading && <Loading />}
        </>
      }
    </>
  );
}

ManageConfigurations.propTypes = {};

export default ManageConfigurations;